import React from 'react';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Catalog from '../components/organisms/Catalog/Catalog';

const Search = () => {
  return (
    <Layout>
      <Seo title="Search" />
      <Catalog isSearch={true} />
    </Layout>
  );
};

export default Search;
